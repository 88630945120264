import styled, { keyframes } from 'styled-components';
import ThemeDefinitions from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

interface AnimatedDivProps {
  visible: boolean;
}
export const AnimatedDiv = styled.div<AnimatedDivProps>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  display: ${props => (props.visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${ThemeDefinitions.bodyBackgroundWithOpacity};
  padding: 40px 0px;
  animation: ${fadeFromLeft} 1s;
  transition: display 0.2s;
  color: #fff;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    align-items: center;

    > div {
      width: 100%;
    }

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #5b5c65;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;

  z-index: 1;
  > div {
    display: flex;
    flex-direction: row;

    @media (max-width: 750px) {
      flex-direction: column;
      padding: 10px;
    }

    > aside {
      display: flex;
      flex-direction: column;
      width: calc(100% - 20px);
      justify-content: center;
      flex-wrap: wrap;
      max-width: 600px;
      min-width: 400px;
      > button {
        margin: 10px auto;
      }
      > form {
        > h1 {
          color: #5b5c65;
        }
      }
      @media (max-width: 750px) {
        max-width: 100%;
        min-width: 100%;
        margin-top: 20px;
      }
    }
  }
`;
