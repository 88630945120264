import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaFileExcel, FaMoneyBill, FaMoneyBillAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { string } from 'yup';

interface ICreateModule {
  lineData: Record<string, any>;
}

export const FinanceReportByProjectModule: React.FC<ICreateModule> = ({ lineData }) => {


  const { addToast } = useToast();


  const gerarRelatorioFinanceiro = async (operation) => {

    addToast({ title: 'Gerando relatório', type: 'info' });

    await downloadit({ url: `/check-saldo/${lineData.url}/${operation}` });


  }

  const generateZabbixReport = async () => {
    addToast({ title: 'Gerando relatório', type: 'info' });

    await downloadit({ url: `/export-zabbix-report/${lineData.url}` });
  }

  const moduleUpdate: React.FC<ICreateModule> = ({ lineData }): JSX.Element => (
    <>
      <button title="Relatório do OMIE" className="lineIcon" type="button" onClick={() => generateZabbixReport()}>
        <FaFileExcel />
      </button>
      <button title="Relatório Financeiro" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiro('')}>
        <FaMoneyBill />
      </button>
      <button title="Listar Saques" className="lineIcon" type="button" onClick={() => gerarRelatorioFinanceiro('saque')}>
        <FaMoneyBillAlt />
      </button></>
  );

  return moduleUpdate({ lineData });
}
