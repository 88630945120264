import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import Speakers from '../../../../../pages/Speakers';
import Sponsors from '../../../../../pages/Sponsors';
import Sobre from '../../../../../pages/Sobre';
import {

  Capa,

} from './styles';
import Program from '../../../../../pages/Program';
import ProgramTheme from '../../Program';
import EventsProjectList from '../../../../../pages/DashboardPages/features/EventsProjectList';
import Products from '../../../../../pages/Products';
 
const SinglePageTheme: React.FC = () => {
  const {configuration, projectHash, eventHash} = useConfiguration();
  
 
 

  
 
  return (
    <>   <div   style={{ display: 'flex' , width: '100%', background: 'rgba(0,0,0,0.3)',  alignItems: 'center', justifyContent:'center'}}>
   
 <>
    {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{margin: '10px'}}><button className="defaultButton">Certificados</button></Link> : <></>}
      { /* <Link to={ !eventHash ? `/app/${projectHash}/inscricao` : `/app/${projectHash}/event/${eventHash}/inscricao`} style={{margin: '5px 10px'}}><button style={{padding: '10px', minWidth:'100px', margin: '5px', width: '120px'}} className="defaultButtonReverse">Novo Cadastro</button></Link> */ }
   
      <Link to={!eventHash ? `/app/${projectHash}/login` : `/app/${projectHash}/event/${eventHash}/login`} style={{margin: '5px 10px'}}><button className="defaultButton" style={{padding: '10px', margin: '5px',minWidth:'100px', width: '200px'}}>Área do inscrito</button></Link>
 
</>  


      </div>
      <Capa style={{  justifyContent:'center'}}  >
 
        <div   style={{ display: 'flex' , width: '100%',  flexDirection:'column',  alignItems: 'center', justifyContent:'center'}}>
        {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{margin: '10px'}}><button className="defaultButton">Certificados</button></Link> : <></>}
     

<Products/>
        
         
         
    

          </div>
  
      </Capa>
      {configuration?.current_event_id_response?.description_title ? <Capa>
        <Sobre/>
      </Capa> : <></>}
     
      <div style={{background: '#fff', width: '100%'}}> <ProgramTheme/>

      </div>
    
   
        <Speakers/>
 
        <div    style={{ display: 'flex', width: '100%',  alignItems: 'center'}}>
        <Sponsors/>
        </div>
   
    </>
  );
};
export default SinglePageTheme;
