import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
 
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { usePages } from '../../../hooks/Pages';
import MainLayout from '../header';


const DefaultLayout: React.FC = ({ children }) => {

const  {menu} = usePages();
  

  return (
    <MainLayout>
     <Wrapper> <Menu menuList={menu} fixedMenu={false} />
      {children}
      
 
      </Wrapper>
      
    </MainLayout>
  );
};

export default DefaultLayout;
