import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';

import ImageSliderBackground from '../../components/ImageBackgroundSlider';
import WhatsappFixed from '../../components/WhatsappFloat';
import { authTitle } from '../../config';

const FooterLayout: React.FC = ({ children }) => {
  return (<><div style={{ zIndex: -1 }}>
    { /* <Particles
      params={{
        particles: {
          lineLinked: {
            enable: false,
            distance: 350
          },
          move: {
            speed: 0.5,
            direction: 'top-right',
            collisions: false,
            outModes: {
              default: 'out',
              left: 'out',
              right: 'out',
              bottom: 'out',
              top: 'out',
            },


          },
          opacity: {
            value: 0.4,
            random: false,
            animation: {
              enable: false
            }

          },
          shape: {
            type: 'circle',
            polygon: {

              nb_sides: 6,

            },

          },

          color: { value: '#009654' },

          number: {
            density: {
              enable: true,
              value_area: 2400,
            },
            value: 60,
          },
          size: {
            value: 4,
            random: true,
          },
        },
      }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1
      }}
    /> */}

  </div>
    <WhatsappFixed message='Suporte Técnico' text={`Olá, eu estou no ${authTitle}`} /></>
  );
};

export default FooterLayout;
