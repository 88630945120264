import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import { authTitle, defaultProjectHash } from '../config';
import { useSocket } from './Socket';
import { useAuth } from './Auth';
import { usePages } from './Pages';


interface ConfigurationState {
  token: string;
  user: Record<string, any>;
}

interface IOption {
  type: string;
  id: string;
  subtype: string;
  value: string;
}

interface ISignInAdmin {
  email: string;
  password?: string;
}

interface IConfiguration {
  _id?: string;
  color?: string;
  program_title?: string;
  program_subtitle?: string;
  logo?: string;
  logo_adicional?: string;
  upper_text?: string;
  header_image?: string;
  data_image?: string;
  lower_text?: string;
  next_date?: Date;
  header_video?: string;
  fixed_image_header?: string;
  background?: string;
  background_color?: string;
  certificates?: string;
  theme_data?: Record<string, any>;
  current_event_id_response?: Record<string, any>;
  active_pages?: Array<Record<string, any>>;

  allow_group?: string;
  allow_pix?: string;
  allow_boleto?: string;
  allow_credit_card?: string;
  allow_cortesy?: string;
}

interface IGame {
  reference?: string;
  points?: number;
  id?: number;
}

interface ConfigurationContextData {

  configuration: IConfiguration;
  setConfiguration(data: IConfiguration): void;
  projectHash: string;
  setProjectHash(data: string): void;
  eventHash: string;
  setEventHash(data: string): void;

}

const ConfigurationContext = createContext<ConfigurationContextData>({} as ConfigurationContextData);

const ConfigurationProvider: React.FC = ({ children }) => {
  const { socket } = useSocket();
  const { user, handleApiErrors } = useAuth();
  const { prepareMenu } = usePages();
  const [configuration, setConfiguration] = useState<Record<string, any>>({});
  const [projectHash, setProjectHash] = useState(defaultProjectHash);
  const [eventHash, setEventHash] = useState(defaultProjectHash);



  const load = async () => {
    // setLoading(true);

    if (projectHash) {

      try {
        const response = await api.get(eventHash ? `/get-project-content/${projectHash}/${eventHash}` : `/get-project-content/${projectHash}`);

        setConfiguration(response.data);



        if (response?.data?.active_pages?.length > 0) {

          prepareMenu({ eventHash, projectHash, active_pages: response?.data?.active_pages });
        }



        /*   setLoading(false);
          setReady(true); */

      }
      catch (err) {

        handleApiErrors(err);
        /*  setLoading(false); */
      }

    }
  }

  useEffect(() => {

    if (window.location.pathname.indexOf('/manager') < 0) {
      if (defaultProjectHash && !projectHash) {
        setProjectHash(defaultProjectHash)
      }
    }

    load();
  }, [projectHash, eventHash])

  return (
    <ConfigurationContext.Provider
      value={{
        configuration,
        setConfiguration,
        eventHash,
        setEventHash,
        projectHash,
        setProjectHash

      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

function useConfiguration(): ConfigurationContextData {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error('useConfiguration must be used within an ConfigurationProvider');
  }

  return context;
}

export { ConfigurationProvider, useConfiguration };
