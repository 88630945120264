export const hash = '5afa5a59-bd32-47dc-aa12-cae83f5572ad';

//export const gateKey = 'ek_test_vW5q0cBNLL7Ch1eTZ1gpReA5DM6ZPW';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Ld9r7cZAAAAABw5wijiLD5PhlWqLivIN9NqFKi_';
export const apiUrl = `https://api-global-v2.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket-v2.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';
export const defaultProjectHash = 'congresso';
//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://congresso-brasileiro-cds.encontrodigital.com.br';
export const supportNumber = `5551981476007`;
export const home = 'Home';
export const haveTranslate = false;
// export const urlWebsite = 'http://localhost:3000';
export const eventName = '1º Congresso Brasileiro de Combate ao Desperdício na Saúde';
export const logo = '/assets/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = '1º Congresso Brasileiro de Combate ao Desperdício na Saúde';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};


export const defaultTheme = {
  defaultBackground: '#003f1a',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: '#003f1a',
    color: '#003f1a',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
