import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../../config';
import { useAuth } from '../../../hooks/Auth';
import { useConfiguration } from '../../../hooks/Configuration';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Sponsors';
 

interface IParams {
  projectHash : string;
  eventHash : string;
}


const MainLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu,setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors } = useAuth();
  const {configuration,setConfiguration, setProjectHash, setEventHash} = useConfiguration();

  const [loading,setLoading] = useState(false);
  const [ready,setReady] = useState(false);
  const {projectHash, eventHash} = useParams<IParams>();
  useEffect(() => {
 
    if(projectHash){
      setReady(true);
      setProjectHash(projectHash || '');
      setEventHash(eventHash ||'');
 
    window.scrollTo(0,0);
    }
    else{

      setProjectHash(projectHash || '');
      setEventHash(eventHash || '');

 
      setReady(true);
      setConfiguration({});
      setMenu([
        {
          title: 'Home',
          target: '/',
          targetID: '/',
          fluid: 1,
          external: 1,
          status: 2,
        }
      ]);
    }
  },[projectHash, eventHash])


  

  return (
    <>
      {children}

      <FooterLayout />
      {configuration?.fixed_image_header && <img src={`${urlWebsite}/${configuration.fixed_image_header}`} className="fixedBottom"/>}
    
     </>
  );
};

export default MainLayout;
