import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp, FaLock } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
  day_reference?: string;
}

interface IProgramFilter {
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate?: string;
  day_reference?: string;
}


const Program: React.FC = () => {
  const [programs, setPrograms] = useState<Array<IProgramFilter>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<IProgramFilter>>([]);
  const [currentDay, setCurrentDay] = useState(0);

  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();

  const { configuration, projectHash, eventHash } = useConfiguration();

  const [filter, setFilter] = useState({
    date: '',
    category: '',
    search: ''
  });

  const addFilter = (column, value) => {

    const newFilter = { ...filter };
    newFilter[column] = value;

    setFilter(newFilter);

  }

  const newSearch = (data) => {

    const newFilter = { ...filter };
    newFilter.search = search;

    setFilter(newFilter);

  }

  useEffect(() => {

    let programsContent: Array<IProgramFilter> = [];

    if (filter.date) {
      programs.map(p => {
        if (p.simpleDate === filter.date) {
          programsContent.push({ ...p });
        }
      })
    }
    else {
      programsContent = [...programs];
    }



    if (filter.category !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programsContent.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.category?.toString()?.toLowerCase();
            const searchValue = filter?.category?.toString()?.toLowerCase();

            const indexText = text === searchValue;


            if (indexText) {

              filteredProgram[index].program.push(p1_program);

            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }



    if (filter.search !== "") {
      const filteredProgram: Array<IProgramFilter> = [];
      programsContent.map((item, index) => {
        filteredProgram.push({ ...item, program: [] });
        return false;
      })

      programs.map((p1) => {

        const index = filteredProgram.findIndex(p => p.simpleDate === p1.simpleDate);
        if (index >= 0) {
          p1.program.map(p1_program => {
            const text = p1_program?.title?.toString()?.toLowerCase();
            const text2 = p1_program?.description?.toString()?.toLowerCase();
            const searchValue = filter?.search?.toString()?.toLowerCase();
            let indexText = text?.indexOf(searchValue);

            if (indexText < 0) {
              indexText = text2?.indexOf(searchValue);

            }


            if (indexText >= 0) {

              filteredProgram[index].program.push(p1_program);
            }
          })
        }
      })

      programsContent = [...filteredProgram];
    }

    setReferenceProgram(programsContent);

  }, [programs, filter])

  const checkUser = async (id) => {

    try {
      const response = await api.post(`/check-activity/${id}`);

      if (response && response.data) {
        window.open(response?.data?.url, '_BLANK');
      }
      else {
        addToast({ type: "error", title: "Inscrição não encontrada" });
      }


    } catch (err) {
      addToast({ type: "error", title: "Inscrição não encontrada" });
    }
  }

  useEffect(() => {
    const load = async () => {
      const program_ref: Array<IProgramFilter> = [];
      const rooms = {};

      try {
        const response = await api.get(eventHash ? `/programs-list/${projectHash}/${eventHash}` : `/programs-list/${projectHash}`);


        if (response?.data?.rows) {
          const responseData = response.data.rows.reduce((prev, elem) => {
            elem.simpleDate = simpleDate(elem.date);
            elem.formatted_date = dateLanguage(elem.date);
            elem.formatted_start_hour = hour(elem.start_hour);
            elem.formatted_end_hour = hour(elem.end_hour);

            if (elem.category) {
              rooms[elem.category] = '';
            }

            prev.push(elem);

            return prev;
          }, []);

          const itens: Array<IOption> = [];
          Object.keys(rooms).map(key => {
            itens.push({ label: key, value: key });
          })


          setRoomList(itens)

          if (responseData) {
            responseData.map(rep => {
              const index = program_ref.findIndex(
                day => day.formatted_date === rep.formatted_date,
              );

              if (index < 0) {
                const item: IProgramFilter = {
                  simpleDate: rep.simpleDate,
                  formatted_date: rep.formatted_date,
                  program: [{ ...rep }],
                };

                if (rep?.day_reference) {
                  item.day_reference = `${rep?.day_reference || ''} `;

                }

                program_ref.push(item);

              } else {
                program_ref[index].program.push({ ...rep });

                if (rep?.day_reference) {

                  program_ref[index].day_reference = program_ref[index].day_reference ? `${program_ref[index].day_reference || ''}<br/> ${rep?.day_reference || ''} ` : `${rep?.day_reference || ''} `;
                }
              }

              console.log(program_ref[index])

            });
          }

          program_ref.map((item, index) => {
            program_ref[index].program.sort((a, b) => {
              return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
            })
          })

          setPrograms(program_ref);
          setReferenceProgram(program_ref);
        }
      }
      catch (err) {
        handleApiErrors(err);
      }
    };
    load();
  }, []);

  const line = program => {
    return (
      <tr style={{ cursor: 'pointer' }} onClick={() => setCurrentProgram(state => state === program.id ? '' : program.id)}>
        <td className="hourDiv" >  {`
              ${program.formatted_start_hour}
              ${program.formatted_end_hour && `às ${program.formatted_end_hour}`}`}</td>  <td style={{ width: '100%' }}>

          <span>

            {program.category ? <><strong className=' category' style={{ background: '#f47a22', borderRadius: '5px', fontSize: '14px', display: 'inline-block', width: 'auto', marginBottom: '5px', fontWeight: 'bold', color: '#fff', padding: '5px 10px' }}> {program.category} </strong><br /></> : <></>}
            {program.mark ? <><strong style={{ fontSize: '12px', width: 'auto', borderRadius: '10px', display: 'flex', alignItems: 'center', fontWeight: 'bold', background: 'none', paddingBottom: '10px', margin: '3px 0px', marginBottom: '5px', color: `#fff` }}><FaLock size={15} style={{ marginRight: '10px' }} /> {program.mark} </strong> </> : <></>}


            <strong style={{ color: '#fff' }}>
              {' '}

              {program.title}
              {' '}


            </strong>

            {program.description && <div className={"hover"}>
              <div style={{ width: '100%' }}>


                <div className="miniPrograma" dangerouslySetInnerHTML={{ __html: program.description }} />

              </div>


            </div>}
          </span>
        </td>
        {user && program.room_reference_id_response && <td><div>
          <span >
            {program.room_reference_id_response &&
              program.room_reference_id_response.title}

            {program.link_url && (
              <a href={`/app/${projectHash}/dashboard/${program.link_url}`} target="_BLANK">
                <button className="defaultButtonMini buttonBlue" type="button">
                  {' '}
                  <FiChevronRight />
                </button>
              </a>
            )}

            {program.activity_id > 0 && (

              <button onClick={() => checkUser(program.activity_id)} className="defaultButtonMini buttonBlue" type="button">
                {' '}
                <FiChevronRight />
              </button>

            )}

            {program.video_id && (
              <div className="optionDiv">
                <button className="defaultButtonMini" type="button"><Favorite type="lesson" subtype="star" id={program.video_id} /></button>
                <Link to={`/app/${projectHash}/dashboard/${program.video_id}`} style={{ margin: '5px' }}>
                  <button className="defaultButtonMini" type="button">
                    {' '}Clique para
                    assistir
                    {/* <FiChevronRight style={{margin: '0px 2px'}} size={24} /> */}
                  </button>
                </Link></div>
            )}
          </span>
        </div>
        </td>}
      </tr>
    );
  };
  const { length } = programs;
  return (
    programs.length > 0 ? <Container id="programacao">
      <h1 style={{ color: '#5b5c65', textAlign: 'center' }}>{translate(configuration?.program_title ? configuration?.program_title : 'Programação')} </h1>
      {configuration?.program_subtitle ? <h2 className='gray' style={{ color: '#5b5c65', marginBottom: '10px' }}>{translate(configuration?.program_subtitle)} </h2> : <></>}
      <p style={{ textAlign: 'center', width: '100%', marginBottom: '20px', color: '#5b5c65' }}>Horário de Brasilia</p>


      {configuration?.theme_data?.program_header === 'no' ? <></> : <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center', width: '100%' }}>
        <button style={{ width: '130px', margin: '5px', background: filter.date === '' ? configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621' : 'none', color: filter.date === '' ? configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#fff' : configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#f16621', border: `2px solid ${configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621'}` }} onClick={() => addFilter('date', '')}>{translate('Todos os dias')}</button>
        {programs.map(filterday => {
          return filterday.simpleDate && <button style={{ width: '60px', margin: '5px', background: filter.date === filterday.simpleDate ? configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621' : 'none', color: filter.date === filterday.simpleDate ? configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#fff' : configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#f16621', border: `2px solid ${configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621'}` }} onClick={() => addFilter('date', filterday.simpleDate)}>{translate(filterday.simpleDate)}</button>
        })}


        <Form
          style={{ display: 'flex', alignItems: 'stretch', background: 'none', margin: '5px' }}
          onSubmit={newSearch}
        >


          <input style={{ height: '43px', padding: '10px' }}
            name="pesquisa"
            type="text"
            placeholder={`${translate('Escreva para pesquisar')}...`}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />

          <button style={{ width: '100px', height: '43px', background: configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621', color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#fff', borderRadius: '10px', border: '0px', margin: '0px 5px' }} type="submit">{translate('Pesquisar')}</button>
        </Form>


      </div>}


      {referenceProgram.map(program => {
        return (program.program.length > 0 ? <div style={{ width: '100%' }}>
          <div className="programDayHeader" >


            {program.formatted_date && <h2 className='gradientBg' style={{ borderRadius: '5px', width: 'auto', background: configuration?.theme_data?.primary_background ? configuration?.theme_data?.primary_background : '#f16621', color: configuration?.theme_data?.primary_color ? configuration?.theme_data?.primary_color : '#fff', marginTop: '30px', padding: '10px', marginLeft: '7px', alignSelf: 'flex-start' }}>{translate(program.formatted_date)}</h2>}
            {program.day_reference && <p style={{ background: '#eee', fontSize: '14px', padding: '5px 10px', lineHeight: '18px', textAlign: 'center', color: '#5b5c65', marginTop: '10px', marginLeft: '7px' }} dangerouslySetInnerHTML={{ __html: program.day_reference }}>{ }</p>}


          </div>


          <table style={{ width: '100%' }}>

            <tbody>{program.program.map(items => line(items))}</tbody>
          </table>

        </div> : <></>)
      })}

    </Container> : <></>
  );
};
export default Program;
