import React, { useEffect, useState } from 'react';

interface IProps {
    limit: number,
    position: number;
}

const ProgressBar: React.FC<IProps> = ({ limit = 100, position = 0 }) => {

    const [currentPosition, setCurrentPosition] = useState(0);
    const width = `${((currentPosition / (limit)) * 100)}%`

    useEffect(() => {
        setCurrentPosition(position);
    }, [position])

    return <div className='progress-bar' key={`progress-bar`} style={{ maxWidth: '100%', minWidth: width, width: '0px', background: 'rgb(0,100,150)', height: '5px' }} />

}

export default ProgressBar;
