import { createGlobalStyle, keyframes } from 'styled-components';
import { defaultTheme } from '../config';


const rotate = keyframes`
from {
  transform: rotate(0deg) ;
}
to{
  transform: rotate(360deg) ;
}
`

export default createGlobalStyle`

@font-face {
    font-family: Montserrat;
    src: url("/assets/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
    font-family: MontserratMedium;
    src: url("/assets/fonts/GothamMedium.ttf") format("truetype");
}


@font-face {
    font-family: MontserratBold;
    src: url("/assets/fonts/Gotham-Bold.otf") format("opentype");
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

html{
  width: 100%;
  min-height: 100vh !important;


}
@media print {
  .breakPage{
    break-before: always;
    page-break-before: always;
    
  }
    }

body {
background: url('/assets/Fundo.jpeg') no-repeat center;
background-size: cover ;
background-attachment: fixed;

@media print {
  background: #fff !important;
  min-height: auto !important;
    }

>iframe {
  position: relative !important;
}



.greenButton {
      padding: 10px 15px;
      background: #003f1a ;
      color: #fff;
      border-radius: 15px;
      font-size: 18px;
      margin:15px  0px ;
      border: 0px;

      @media (max-width: 750px) {
        margin:0px  0px ;
  }
    }

    .greenButtonMini {
      padding: 5px 15px;
      background: #139b61  !important ;
      color: #fff !important;
      border-radius: 15px;
      font-size: 14px !important;
      margin:5px  0px ;
      border: 0px;

      @media (max-width: 750px) {
        margin:0px  0px ;
  }
    }

  .greenButton2{
    padding: 10px 15px;
      background: #b2d349 ;
      color: #003f1a;
      border-radius: 15px;
      font-size: 18px;
      margin:15px  0px ;
      border: none;
      border: 0px;

      @media (max-width: 750px) {
        margin:0px  0px ;
  }
  }

.gradientBg {
  width: 100%;
  background: rgb(0,152,92) !important;
background: linear-gradient(90deg, rgba(0,152,92,1) 0%, rgba(178,211,73,0.8183648459383753) 100%)  !important;
}

.gradientBg2 {
  width: 100%;
  background: rgb(178,211,73) !important;
background: linear-gradient(90deg, rgba(178,211,73,1) 0%, rgba(244,121,32,0.8491771708683473) 100%) !important;
}

.react-select___menu {
    text-align: left;
    justify-content: left;
    color: #5b5c65;
    width: 100%;
    z-index: 100000000 !important;
    transform: scale(1) ;
  }

.productSuccess{
  font-size: 14px !important;
  p,strong,a {
    font-size: 14px !important;
    color: #5b5c65 !important;
    line-height: 20px
  }

  a{
    color: rgb(0,50,100) !important;
  }
}

.medium {
  font-family:  'MontserratMedium',Helvetica,  sans-serif;
}

.shadowButtonSelect{
  background: #efefef ;
  border: 2px solid #ddd;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
  border-radius: 10px;
  padding: 15px ;
  display: flex ;
  justify-content: center;
 
  width: 100%;
  transition: 0.5s all ;

  &:hover{
    background: #ddd;
  }
  
}

.slick-track, .slick-slider, .slick-list, #sliderList{
  width: 100%;
}

.slick-slider, .slick-list, #sliderList{
  margin: 0px;
  padding: 0px;

  img{
    width: 100%;
  }


}

.slick-dots{
  bottom: 10px;

  button{
    height: 30px;
    width: 30px;
    &:before{
      height: 30px;
    width: 30px;
    font-size: 10px;
    }
    
  }
}

 
  background-position:  top;
  
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;



  color: #fff;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0 !important;
  min-height: 100vh !important;
  min-height: 100% !important;
  position: relative;



}

.shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.padding{
  padding: 15px;
}

.margin{
  margin: 15px;
}

.shadowBox{
  display: flex;
    background: rgb(255, 255, 255) !important;
    box-shadow: rgb(7 13 28 / 25%) 0px 10px 100px;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#root{
  min-height: 100vh;
 


}

body, input, button{
  font-family: Helvetica,  sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
font-weight: bold;
font-family:   Helvetica,  sans-serif;
width: 100% ;

}

 

strong{
  font-size: 14px;
}
p{
  font-size: 14px;
}

h2{

      font-size: 16px;
}

p, span{
  font-family: Helvetica,  sans-serif;
}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  >svg {
    margin: 0 5px;
  }
}

.productBlock{
    transition: 0.3s;
    width: 100% ;
    background: #fff ;
    &:hover{
      background: #f5f5f5 ;
    }

    svg{
      
      cursor: pointer;
    }

    @media (max-width: 750px) {
      >aside{
      flex-direction: column !important;
     >p{
        font-size: 10px !important;
        line-height: 12px !important;
      }
      }
    }
  }

.wave {

  width: 200%;
  margin-left: -20%;
  z-index:0;

  @media (max-width: 1250px) {
    width: 400%;
    margin-left: -50%;
  }

  @media (max-width: 750px) {
    width: 800%;
    margin-left: -50%;
  }



}


.computerOnly{
display: flex !important;
@media (max-width: 1200px) {
    display: none !important;
  }
}

.mobileOnly{
display: none !important;
@media (max-width: 1200px) {
    display: flex !important;
  }
}

.fixedBottom{
    z-index: 0;
    position: fixed;
    bottom: -30px;
    right: 0px;
    width: 45%;
}

div.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);

      svg{
        cursor: pointer;
        transition: 0.4s all ;
        &:hover{
          transform: scale(1.1) ;
        }
      }
   
    }

button.defaultButton {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      max-width: 250px;
      padding: 20px;
      text-align: center;
      background: #fff !important;
      border: 2px solid rgb(0, 15, 64);
      color: rgb(0, 15, 64)  !important;
font-weight: bold;
      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;

      border-radius: 10px;
      box-shadow: 0px 5px 15px rgba(255,255,255,0.3);
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 5px 5px rgba(171, 230, 255,0.7);
        background: rgb(171, 230, 255) !important;
        border-color: #fff;
      }
    }


button.defaultButtonRed {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      background: red !important;
      color: #fff  !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .defaultButtonReverse {
         margin-top: 10px;
      width: 200px;
      min-width: 200px;
      padding: 20px;
      text-align: center;
      flex-direction: column;
      display: flex ;
      justify-content: center;
      align-items: center;
 
      color: rgb(0, 15, 64)  !important;
      font-weight: bold;

      background: rgb(171, 230, 255) !important;

      display: flex;
      justify-content: center;
      text-align: center;

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;
    
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .tip{
      padding: 5px;
     font-size: 14px;
      width:100%;
      text-align: left;
      font-weight: bold;
   
      color: #916f00 !important;
      display: flex ;
      justify-content: flex-start;
      align-items: center;

      >svg {
        animation: ${rotate} 5s infinite ;
      }
     
  
    }

    .rotateAnimation{
      animation: ${rotate} 5s infinite ;
    }

    .simpleButton {
         margin-top: 0px;
 
  
      padding: 5px;
      text-align: center;
      background: #fff !important;
      color: #5b5c65  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #5b5c65  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }


    .defaultButtonReverseMini {
         margin-top: 0px;
      width: 100%;
      max-width: 200px;
      padding: 5px 10px;
      text-align: center;
      background: #f16621 !important;
      color: #fff  !important;
      font-weight: bold;

      display: flex;
      justify-content: center;
      text-align: center;

      >a{
        color: #fff  !important;
        font-weight: bold;

      }

      transition:  0.5s all;
      border: 0;
      border-radius: 10px;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0px 5px 5px rgba(255,255,255,0.3);
      }
    }

    .product_footer{
      p, strong{
        font-size: 14px !important;
        display: block ;
        line-height: 16px  !important;

      }

      td:nth-child(3) {
  background: #34366a !important;
  color: #fff !important;
  font-weight: bold;
}

    }

    button.defaultButtonMini,    button.defaultButtonMiniRed,  button.defaultButtonMini2, button.buttonBlue {
         margin-top: 0px !important;
         padding: 5px 10px !important;
      width:auto !important;
      min-width:auto ;
      font-size: 18px !important;
      >p{
        font-size: 18px !important;
      }

      text-align: center !important;
      justify-content: center !important;
      background: #003f1a !important;
      color: #fff  !important;

      display: flex  !important;
      justify-content: center !important;


      transition: background 0.5s;
      border: 0;
      border-radius: 10px;

      &:hover {
        background: ${defaultTheme.defaultBackground}  !important;
      }
    }

    button.defaultButtonMiniRed{
      background: #f47920 !important;

      &:hover {
        background: #f47920  !important;
      }
    }


    button.defaultButtonMini2{
      padding: 10px 20px  !important ;
      margin-top: 25px ;
    }

    button.buttonBlue{
      background: rgb(0,100,150) !important;

      &:hover {
        background: rgb(0,120,180) !important;
      }
    }


    .closeButton{
      padding: 10px;
      border: 0;
      opacity: 0.8;
      background: #555;
      color: #fff;
      transition: 0.5s all;

      margin: 5px;
      &:hover{
        opacity: 1;
      }
    }


    a {
      text-decoration: none !important;
    }


    .lineIcon {
      margin:5px;
      background: #fff;
       border: 1px solid #5b5c65;
       border-radius: 50%;
       height: 35px;
       width: 35px;
       padding: 5px;
       
       >svg{
         width: 25px !important;
         font-size: 25px;

       }
       display: flex;
       align-items: center;
       justify-content: center;
    }

    .videoContainer{
      width: 100%;
      position: relative;
      height: auto;
   
    }

form {
    >div{
  
  background: #fff !important;
  color: #5b5c65  !important;
 

  span{
    color: #5b5c65  !important;
  }

  input {
    color: #5b5c65  !important;

    &::placeholder {
    color: #5b5c65  !important;
  }
  }
}
}
`;
