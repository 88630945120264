import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine, Error } from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { hash, urlWebsite } from '../../config';
import CheckboxInput from '../../components/Forms/CheckboxInput';
import * as Yup from 'yup';
import { useLanguage } from '../../hooks/Language';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';
import QRCode from 'qrcode.react';
import Research from './FormContainer';
import Exam from './FormContainer2';
import { MdError } from 'react-icons/md';
import { useModal } from '../../hooks/Modal';
import { useParams } from 'react-router-dom';
import RenderCertificate from './RenderCertificate';
import downloadCertificate from './ExportPDF';
interface ICertificates {
  certificate_id_response: { title: string, project_id: string };
  hash: string;
}

interface IOptions {
  id: string;
  value: string;
  label: string;


}




interface IAnswers {
  id: string;
  value: string;
  label: string;
}

interface IQuestion {
  title: string;
  options: Array<{ question: string, value: string }>;
  correct: string;
}

interface IQuestions {
  title: string;
  questions: Array<IQuestion>
}

interface IResponse {
  number: number;
  answers: Array<string>;
}

interface ICertificateUser {
  id: string;
  answers: Array<Record<string, any>>;
  hash: string;
  certificate_id_response: {
    proof: IQuestions
  },
  user_id: number;
}





const Certificados: React.FC = () => {
  const [certificates, setCertificates] = useState<Array<ICertificates>>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();
  const { user } = useAuth();
  const [success, setSuccess] = useState(true);
  const [getNumber, setGetNumber] = useState(0);
  const [emailInfo, setEmailInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const { addModal } = useModal();
  const { projectHash } = useParams<Record<string, string>>();



  const [certificate, setCertificate] = useState<ICertificateUser>({} as ICertificateUser);
  const [errorMessageText, setErrorMessageText] = useState('');

  const [currentQRCode, setCurrentQRCode] = useState('');

  useEffect(() => {
    const message = setTimeout(() => {
      if (errorMessageText) {
        setErrorMessageText('');
      }
    }, 2000)



    return () => clearTimeout(message);
  }, [errorMessageText])



  const generateCertificate = (certificateInfo) => {




    downloadCertificate({ certificate: certificateInfo })




  }



  const verify = async data => {
    setLoading(true);
    if (!data.email) {
      setLoading(false);
      setErrorMessageText('Preencha o campo email');
      return;
    }
    try {
      const { email } = data;

      const response = await api.post(`/verify-certificates`, { email: email.toLowerCase().trim(), project_id: projectHash });

      if (!response) {
        setLoading(false);
        setErrorMessageText('Nenhum certificado encontrado');
        return;
      }

      setEmailInfo(email);

      setCertificates(response.data.content);

      // setSuccess(true);

      setActive(true);
      setLoading(false);
    } catch (err) {

      setLoading(false);
      setErrorMessageText('Nenhum certificado encontrado');
      return;
    }
  };

  const [questions, setQuestions] = useState<IQuestions>({} as IQuestions);

  const resetVerify = () => {
    setCertificates([]);
    setActive(false);
  };

  const checkAnswers = (data) => {

    const response: IResponse = {
      number: 0,
      answers: []
    }

    let x = 1;
    console.log(certificate?.certificate_id_response)
    certificate?.certificate_id_response?.proof?.questions.map((question) => {

      if (data[`p${x}`] && data[`p${x}`][0] === question.correct) { response.number = response.number + 1; }
      response.answers.push(data[`p${x}`][0]);

      x = x + 1;

    })

    return response;


  }

  const checkPerguntas = async (data) => {


    setLoading(true);



    try {
      formRef.current?.setErrors({});






      /* const response = await api.post(`/signup`, {
        ...data
      });*/


      setLoading(false);

      let answers = checkAnswers(data);





      await api.post(`/confirmar-certificado`, { id: certificate?.id, answers, email: emailInfo });
      setGetNumber(answers.number);
      setSuccess(true);
      window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificados/${certificate?.hash}`;
      //generateCertificate(certificate);
      //addModal({ title: '', content: <RenderCertificate certificate={certificate} />, theme: 'whiteModal2' });
      //window.location.href = `http://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${certificate?.hash}`;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);
        setLoading(false);
        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
        err?.response?.data?.error ||
        'Erro ao acessar seu certificado',
      ]);
      setLoading(false);
    }
    setLoading(false);
  };

  const readQuestions = useCallback(() => {
    const questions: Array<JSX.Element> = [];
    let x = 1;
    certificate?.certificate_id_response?.proof?.questions?.map((question) => {

      const answers: Array<IOptions> = [];

      question?.options?.map((option) => {
        answers.push({
          id: option.question,
          value: option.value,
          label: option.question,
        });


      });

      questions.push(<CheckboxInput unique name={`p${x}`} preText={<>{`${x}. `}<p dangerouslySetInnerHTML={{ __html: question.title }} /></>} options={answers} />);
      x = x + 1;
    });

    return questions;
  }, [certificate]);


  const handleCertificate = async (e, cert) => {
    e.preventDefault();
    setLoading(true);


    /* verificar se a pesquisa foi criada */


    if (cert?.certificate_id_response?.satisfaction_survey_id) {
      const researchCheck = await api.get(`/check-research/${cert.user_id}/${cert?.certificate_id_response?.satisfaction_survey_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal max700',
          title: '',
          content: <Research projectHash={projectHash} handle={() => handleCertificate(e, cert)} survey_id={cert?.certificate_id_response?.satisfaction_survey_id} certificate={cert} content={researchCheck?.data?.content} id={cert.user_id} />,
          key: 'research',


        });
        setLoading(false);
        return;
      }

      if (cert?.certificate_id_response?.exam_id) {
        const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

        if (researchCheck?.data?.message !== 2) {
          addModal({
            theme: 'whiteModal max700',
            title: '',
            content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
            key: 'exam',

          });
          setLoading(false);
          return;
        }

        else {
          //generateCertificate(cert);
          //addModal({ title: '', content: <RenderCertificate certificate={cert} />, theme: 'whiteModal2' });
          window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
          setLoading(false);
        }


      }
      else {
        // generateCertificate(cert);
        //addModal({ title: '', content: <RenderCertificate certificate={cert} />, theme: 'whiteModal2' });
        window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else if (cert?.certificate_id_response?.exam_id) {
      const researchCheck = await api.get(`/check-exam/${cert.user_id}/${cert?.certificate_id_response?.exam_id}`);

      if (researchCheck?.data?.message !== 2) {
        addModal({
          theme: 'whiteModal',
          title: '',
          content: <Exam handle={() => handleCertificate(e, cert)} exam_id={cert?.certificate_id_response?.exam_id} content={researchCheck?.data?.content} id={cert.user_id} />,
          key: 'exam',

        });
        setLoading(false);
        return;
      }

      else {
        //generateCertificate(cert);
        //addModal({ title: '', content: <RenderCertificate certificate={cert} />, theme: 'whiteModal2' });
        window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
        setLoading(false);
      }


    }

    else {
      //generateCertificate(cert);
      //addModal({ title: '', content: <RenderCertificate certificate={cert} />, theme: 'whiteModal2' });
      window.location.href = `https://api-certificados.encontrodigital.com.br/${hash}/certificadosv2/${cert.hash}`;
      setLoading(false);

    }



  }

  return (<aside style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px' }}>
    <Container style={{ padding: '30px 10px', background: '#fff', borderRadius: '15px' }}>
      {!active && (
        <Form style={{ textAlign: 'center' }} onSubmit={verify} ref={formRef}>

          <h2 style={{ marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{projectHash === 'curso-codigo-cerebro-capacitacion-en-trombectomia-mecanica-para-latinoamerica' ? 'Ver mis certificados' : 'Visualizar meus certificados'}</h2>


          <Input placeholder={projectHash === 'curso-codigo-cerebro-capacitacion-en-trombectomia-mecanica-para-latinoamerica' ? 'Introduce tu correo electrónico' : "Insira seu email"} name="email" type="text" />
          <button className="defaultButton" style={{ margin: '15px auto', width: '100%' }} type="submit">
            Verificar
          </button>
        </Form>
      )}
      {active && success && (
        <>
          <h2 style={{ marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{projectHash === 'curso-codigo-cerebro-capacitacion-en-trombectomia-mecanica-para-latinoamerica' ? 'Mi certificado' : 'Meus Certificados'}</h2>
          <div>
            {certificates && certificates.length > 0 ? (
              certificates.map(cert => (cert?.certificate_id_response?.project_id === projectHash ?
                <CertificateLine>
                  <a onClick={(e) => handleCertificate(e, cert)}
                    href={'#'}
                    target="_BLANK"
                  >
                    <button type="button" className="defaultButton">{projectHash === 'curso-codigo-cerebro-capacitacion-en-trombectomia-mecanica-para-latinoamerica' ? 'ACCESO' : 'ACESSAR'}</button>
                  </a>
                  <p>{cert?.certificate_id_response?.title || ''}</p>
                  <div style={{ display: 'none' }}>
                    <QRCode style={{ opacity: 0, display: 'none' }} renderAs="canvas" value={`https://certificados.encontrodigital.com.br/validar-certificado/${cert?.hash}`} id={`canvas-cert-${cert.hash}`} />
                  </div>
                </CertificateLine> : <></>
              ))
            ) : (
              <p style={{ color: '#333' }}>Nenhum certificado encontrado</p>
            )}
          </div>

          <button className="defaultButton"
            onClick={() => resetVerify()}
            style={{ margin: '5px auto', width: '100%', maxWidth: '200px' }}
            type="button"
          >
            Voltar
          </button>
        </>
      )}

      {loading && <Loader message="Verificando" />}

      {errorMessageText ? <aside style={{ background: 'rgb(150,0,0', color: '#fff', padding: '20px', borderRadius: '15px', marginBottom: '15px', fontSize: '12px', fontWeight: 'bold' }}>{errorMessageText}</aside> : <></>}


    </Container>


  </aside >
  );
};
export default Certificados;
