import { Form } from '@unform/web';
import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';

interface IProps {
    coupon: string;
    setCoupon: React.Dispatch<React.SetStateAction<string>>;
    setCouponDiscount: React.Dispatch<React.SetStateAction<number>>;
    couponDiscount: number;
    productPrice: number;
    quantity: number;
    product_id: string;
}

const DiscountCoupon: React.FC<IProps> = ({
    coupon = '',
    setCoupon,
    setCouponDiscount,
    couponDiscount,
    productPrice,
    quantity,
    product_id
}) => {
    const { projectHash } = useConfiguration();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(coupon);
    const [valid, setValid] = useState('');

    const checkCupon = async (couponValue) => {

        if (!couponValue) {
            setValid('Preencha o cupom de desconto');
            return;
        }

        const couponV = couponValue?.toUpperCase();

        setValid('');
        setLoading(true);

        try {
            const response = await api.post(`/check-coupon/${projectHash}`, {
                hash: couponV,
                product_id: product_id
            });

            const data = response.data;

            if (!data?.product) {
                setValid(translate('Coupon inválido'));
                setLoading(false);
                return;
            }

            setCoupon(couponV);
            setCouponDiscount((productPrice - data?.price));
            setLoading(false);
        }
        catch (err) {

            setValid(err?.response?.data?.message || err?.response?.data?.error || 'Coupon inválido');
            setLoading(false);
        }





    }

    const { translate } = useLanguage();

    return <aside style={{ margin: '10px auto', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <h2 style={{ margin: '10px 0px' }}>{translate('Tenho um coupon de desconto')}.</h2>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} name='couponValue' onChange={(e) => setValue(e?.target?.value)} value={value} placeholder='' type='text' />
            <button style={{ width: '70px', padding: '10px', borderRadius: '5px', border: '1px solid #ddd' }} onClick={() => checkCupon(value)} type="button">{translate('Validar')}</button>
        </div>
        {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

        {couponDiscount > 0 ? <p style={{ margin: '5px 0px', color: 'rgb(0,150,0)', width: '100%', textAlign: 'right' }}>R$ {format(couponDiscount * quantity)} {translate(`de desconto`)}</p> : <></>}
        {valid !== '' ? <p style={{ margin: '5px 0px', color: 'rgb(150,0,0)', textAlign: 'right', width: '100%' }}>  {translate(valid)}</p> : <></>}
    </aside>

}

export default DiscountCoupon;
