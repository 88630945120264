import React from 'react';

import VideoPlayer from '../../components/IframePlayer';

import {

  Capa, Container,



} from './styles';

import ProjectList from '../ProjectList';
import EventList from '../DashboardPages/features/EventsList';
import { useLanguage } from '../../hooks/Language';
import Products from '../Products';
import { useConfiguration } from '../../hooks/Configuration';
import { Link } from 'react-router-dom';
import Program from '../Program';
import Speakers from '../Speakers';
import { FaWineBottle, FaWineGlass, FaWineGlassAlt } from 'react-icons/fa';



const Home: React.FC = () => {
  const { translate } = useLanguage();
  const { configuration } = useConfiguration();
  const { language } = useLanguage();


  return (<><Container>
    <Capa>
      <div className='header'>
        <img src="/apoio/icone.png" />
        <div>
          <h2><strong className='green'>{translate('1º Congresso Brasileiro')}</strong><strong className='gray'>{translate(' de Combate ao Desperdício na Saúde')}</strong></h2>

          <div className='displayBlock computerOnly'>
            <aside className='greenButton'>Dias 28 e 29 de abril de 2023</aside>
            <Link to="/app/congresso/certificados"><button className='greenButton2'>Certificados</button></Link>
          </div>
        </div>

      </div>

      <div className='displayBlock mobileOnly'>
        <aside className='greenButton'>Dias 28 e 29 de abril de 2023</aside>
        <Link to="/app/congresso/certificados"><button className='greenButton2'>Inscreva-se</button></Link>
      </div>



    </Capa>
    <Capa>
      <div className='header'>
        <img className='mapicon' src="/apoio/map.png" style={{ width: '50px;' }} />
        <div>
          <p className='mapiconP'> {translate('No Hotel Recanto Business Center')}<br />
            {translate('Recanto Maestro, Restinga Sêca/RS')}  </p>


        </div>

      </div>




    </Capa>
  </Container>
    <div className='gradientBg' id="speaker">
      <Container >

        <Capa>
          <Speakers />




        </Capa>

      </Container>
    </div>
    <Container id="program">

      <Capa>
        <Program />




      </Capa>
    </Container>
    {/* <div className='gradientBg2'>
      <Container >
        <Capa>
          <h2 className='white'>Programação social</h2>

          <div className='header'>
            <img src="/apoio/icone.png" />
            <div>
              <h2> Jantar de confraternização</h2>

              <div className='displayBlock computerOnly'>
                <aside className='greenButton'>Dia 28 de abril de 2023, às 20h</aside>

              </div>
            </div>

          </div>


        </Capa>
      </Container>
  </div> */}
    <div className='gradientBg' id="hotel">


      <Capa style={{ display: 'flex', flexDirection: 'row', padding: '0px 0px' }}>

        <div className='p50' style={{ padding: '25px', margin: '0px' }}>
          <h2 style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '28px' }}><strong style={{ fontSize: '28px' }} className='green'>{translate('Hotel do Evento – Recanto Business Center')}</strong> </h2>
          <p style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '18px', lineHeight: '20px', }}>    <strong style={{ fontSize: '18px' }}>Aproveite o tarifário especial para reservas dos participantes do evento, com direito a ingresso para as Termas Romanas.</strong>
            <br /><br />
            Solicitar reserva pelo whatsapp ou telefone:<br />
            Whatsapp: 55 99969.2763<br />
            Ligação: 55 3192.0069<br />
            <br />
            Nome da reserva: CONGRESSO UNIMED<br />
            Nº da reserva: 23.669</p>
          <a href="https://wa.me/55999692763" target='_BLANK' style={{ alignSelf: 'left' }}> <button className='defaultButton'>RESERVAR PELO WHATSAPP</button></a>
        </div>

        <div className='p50' style={{ margin: '0px', backgroundSize: 'cover', position: 'relative' }} >
          <VideoPlayer keyName='hotelvideo' id="https://iframe.mediadelivery.net/embed/103259/344985ad-c2ae-4212-b264-30a77028d708?autoplay=false" />

        </div>
      </Capa>
    </div>






    <div className='gradientBg' id="local">


      <Capa style={{ display: 'flex', flexDirection: 'row', padding: '0px 0px' }}>

        <div className='p50' style={{ background: 'url("/apoio/maestro.png")', margin: '0px', backgroundSize: 'cover', minHeight: '50vh' }} />
        <div className='p50' style={{ padding: '25px', margin: '0px' }}>
          <h2 style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '28px' }}><strong style={{ fontSize: '28px' }} className='green'>{translate('Recanto Maestro')}</strong> </h2>
          <p style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '16px', lineHeight: '20px', }}>O Centro Internacional de Arte e Cultura Humanista Recanto Maestro, localizado entre as belas paisagens da Região Central do Rio Grande do Sul, é um polo de educação, cultura, empreendedorismo, arte, ciência e inovação com atuação local e diálogo internacional. O Recanto Maestro reúne a proximidade à vida moderna e movimentada das cidades ao seu entorno com a agradável, tranquila e revigorante vida do ambiente natural, ecobiológico, saudável, harmonioso e turístico.</p>

        </div>


      </Capa>

    </div>
    <div className='gradientBg'>


      <Capa style={{ display: 'flex', flexDirection: 'row', padding: '0px 0px' }}>


        <div className='p50' style={{ padding: '25px', margin: '0px' }}>
          <h2 style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '28px' }}><strong style={{ fontSize: '28px' }} className='green'>{translate('Santa Maria')}</strong> </h2>
          <p style={{ width: '100%', fontSize: '16px', lineHeight: '20px', maxWidth: '600px', margin: '10px auto' }}>Cidade alegre, cosmopolita e multifacetada, Santa Maria tem muito a oferecer a moradores e visitantes. Seus diversos atrativos histórico-culturais se unem à gastronomia variada e às belezas naturais, com destaque para o patrimônio paleontológico, resultando em várias opções de lazer, cultura e entretenimento.<br /><br />
            Somos uma cidade com localização privilegiada, com acessos rodoviários a todas as partes do Estado e aeroporto com rota diária à Capital. Possuímos baixo custo de vida e somos referência em segurança pública, além de sermos referência regional para o comércio e o serviço e polo de educação e de saúde, características que nos fazem ter uma população extremamente acolhedora.</p>
          <br />
          <a href="http://www.santamariaturismo.com.br/index.php/pt/" target="_BLANK"><button className='greenButton'>Saiba mais!</button></a>
        </div>

        <div className='p50' style={{ margin: '0px', backgroundSize: 'cover', minHeight: '50vh', position: 'relative' }} >

          <iframe style={{ width: '100%', height: '100%', position: 'absolute', top: '0px', left: '0px' }} src="https://www.youtube.com/embed/uV8bdsJOf6c" />

        </div>
      </Capa>

    </div>

    <Container>

      <Capa>
        <div>
          <h2 style={{ width: '100%', maxWidth: '600px', margin: '10px auto', fontSize: '28px', textAlign: 'center', color: '#5d5d5d' }}><strong style={{ fontSize: '28px', textAlign: 'center', color: '#5d5d5d' }} className='green'>{translate('Mais hotéis')}</strong> </h2>
          <br />
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#5d5d5d' }}>
            <strong style={{ fontSize: '18px', textAlign: 'center', color: '#5d5d5d' }}>No Recanto Maestro</strong>
            <br />
            <br />Resort Termas Romanas <br /><a style={{ color: '#333' }} href="https://www.resorttermasromanas.com/" target='_BLANK'>https://www.resorttermasromanas.com/</a>

            <br /><br />Hotel Capo Zorial <br /> <a style={{ color: '#333' }} href="https://hotelcapozorial.com.br/" target='_BLANK'>https://hotelcapozorial.com.br/</a>

            <br /> <br />Pousada Recanto Maestro <br /> <a style={{ color: '#333' }} href="https://www.pousadarecanto.com/" target='_BLANK'>https://www.pousadarecanto.com/</a>

            <br /> <br />Estalagem Monte Rei <br /><a style={{ color: '#333' }} href="https://montereiestalagem.com.br/" target='_BLANK'>https://montereiestalagem.com.br/</a>

            <br /><br /> <strong style={{ fontSize: '18px' }}>Em Santa Maria</strong>

            <br /><br />Altadomo Hotel<br /> <a style={{ color: '#333' }} href="https://www.altadomohotel.com.br/" target='_BLANK'>https://www.altadomohotel.com.br/</a>

            <br /><br />Itaimbé Palace Hotel <br /><a style={{ color: '#333' }} href="https://www.hotelitaimbe.com.br/" target='_BLANK'>https://www.hotelitaimbe.com.br/</a>

            <br /><br />Park Hotel Morotin <br /><a style={{ color: '#333' }} href="https://morotin.com.br/" target='_BLANK'>https://morotin.com.br/</a>
          </p>  </div>
      </Capa>


    </Container>

    {/* <Container>

      <Capa>
        <h2 className='gray' >Atrativos turísticos</h2>

        <div className='header' style={{ width: '100%', maxWidth: '600px', margin: '0px auto' }}>
          <div style={{ minWidth: '120px', width: '120px', borderRadius: '50%', background: 'url("/apoio/amancio.jpg")', margin: '0px', backgroundSize: 'cover', height: '120px' }} />

          <div>
            <h2><strong className='gray' style={{ fontSize: '24px', color: '#003f1a' }}>{translate('Vinícola Domus Mea')}</strong> </h2>
            <p style={{ fontSize: '14px', color: '#5c5c5c', lineHeight: '18px', maxWidth: '600px', width: '100%' }}>
              Visita mediante agendamento.<br />
              Rua Recanto Maestro - São João do Polêsine, RS - 972330-000<br />
              (55) 99143-2537<br />
              contato@vinicoladomusmea.com.br<br />
              <strong style={{ color: '#003f1a' }}> <a style={{ color: '#003f1a' }} target="_BLANK" href="http://www.domusmea.com.br/"> http://www.domusmea.com.br/ </a></strong>
            </p>
            <div className='displayBlock '>


            </div>
          </div>

        </div>

        <div className='header' style={{ width: '100%', maxWidth: '600px', margin: '0px auto' }}>
          <div style={{ minWidth: '120px', width: '120px', borderRadius: '50%', background: 'url("/apoio/dalla.png")', margin: '0px', backgroundSize: 'cover', height: '120px' }} />

          <div>
            <h2><strong className='gray' style={{ fontSize: '24px', color: '#003f1a' }}>{translate('Vinícola Dalla Corte')}</strong> </h2>
            <p style={{ fontSize: '14px', color: '#5c5c5c', lineHeight: '18px', maxWidth: '600px', width: '100%' }}>
              Horário de Funcionamento: Segunda a Sábado das 8:30 às 12:00 e 14:00 às 19:00; Domingo das 09:00 às 12:00 e 16:00 às 19:00
              Estr. Mun. Ângelo Berleze, 605 - Km Três, Santa Maria - RS, 97095-640<br />
              (55) 3226-3362<br />
              contato@vinhosdallacorte.com.br<br />
              <strong style={{ color: '#003f1a' }}> <a style={{ color: '#003f1a' }} target="_BLANK" href="http://www.vinhosdallacorte.com.br/"> http://www.vinhosdallacorte.com.br/ </a></strong>
            </p>
            <div className='displayBlock '>


            </div>
          </div>

        </div>



      </Capa>
    </Container>

    <Container>

      <Capa>
        <h2>Hotelaria</h2>


      </Capa>
</Container> */}
    {/* Container>
      <Capa id="inscricao">
        <h2 className='gray' style={{ width: '100%', textAlign: 'center' }} >Inscrição</h2>
        <Products />


      </Capa>



</Container> */}
  </>
  );
};
export default Home;
